<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div class="dF">
			<a-icon type="arrow-left" @click="$router.go(-1)" />
		</div>
		<div>
			<a-tabs size="large" default-active-key="1">
				<div slot="tabBarExtraContent" class="dF aC" style="gap: 20px">
					<div class="dF" style="gap: 5px">
						<span>Download:</span>
						<a @click.prevent="getCSV(filterDataRecords)" href="javascript:;" class="text-primary">CSV</a>
					</div>
					<div>
						<div @click="showFilter" class="dF aC px-3 py-2 ml-3" style="background-color:white; border-radius:4px; border:1px solid #ECEBED; cursor:pointer; line-height: normal;">
							<div class="mr-2">Filters</div>
							<i class="fe fe-filter" />
						</div>
					</div>
				</div>
				<a-tab-pane key="1" :tab="selectedChart.title === 'Lead Velocity Rate'
					? 'Qualified Lead Details'
					: 'Lead Details'
					">
					<a-card class="table-card">
						<a-table class="tableStyle" :columns="columns" :data-source="filterDataRecords"
							:row-key="(record) => record.id" :scroll="{ x: 1200 }">
							<template #leadScore="leadScore">
								<a-progress status="normal" :percent="+((leadScore/$store.state.reports.maxLeadScore) * 100).toFixed(2)" size="small" strokeColor="#f7941e">
									<template #format="percent">
										<span style="font-size: medium;">
											{{ leadScore }}
										</span>
									</template>
								</a-progress>
							</template>
							<template #leadSourceUrl="leadSourceUrl">
								{{ getFilteredSourceUrl(leadSourceUrl) }}
							</template>
							<template #value="value">
								$ {{ value.toLocaleString("en-US") }}
							</template>
							<template #leadCreatedAt="leadCreatedAt">
								{{ formatDate(leadCreatedAt) }}
							</template>
							<template #createdAt="createdAt">
								{{ formatDate(createdAt) }}
							</template>
							<template #lastInteraction="lastInteraction">
								{{ formatDate(lastInteraction) }}
							</template>
							<template #closeDate="closeDate">
								{{ formatDate(closeDate) }}
							</template>
						</a-table>
					</a-card>
				</a-tab-pane>
			</a-tabs>
		</div>
		<FilterDrawer :visible="filter" @close="closeFilter" @filter="applyFilter" />
	</div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import FilterDrawer from "@/components/common/FilterDrawer";
import { formatDate } from 'bh-mod'

export default {
	components:{
		FilterDrawer
	},
	data: () => ({
		loading: true,
		dataRecords: [],
		filter: {
			filter:{
				source:['any']
			},
			visible: false
		}
	}),

	computed: {
		...mapState(["selectedChart", "records", "instance"]),

		filterDataRecords(){
			let records = JSON.parse(JSON.stringify(this.dataRecords));
			if (this.filter.filter.source[0] !== 'any') {
				records = records.filter(r => this.filter.filter.source.includes(r.leadSource))
			}
			return records;
		},

		columns() {
			return this.getColumns();
		},

		filename() {
			return `Lead Report(${moment(this.currentStartDate).format(
				"ll"
			)} - ${moment(this.currentEndDate).format("ll")})`;
		},

		corporateInstance() {
			return (this.instance && this.instance.projectType === 'corporate')
		},

		selectedProjects() {
			return this.$store.state.selectedProjects
		},
	},

	created() {
		this.loading = false;
	},

	methods: {
		formatDate,
		showFilter() {
			this.filter.visible = true;
		},
		closeFilter() {
			this.filter.visible = false;
		},
		applyFilter(filter){
			console.log('Applying filter...', filter);
			this.filter.filter = JSON.parse(JSON.stringify(filter));
		},
		getColumns() {
			if (
				this.selectedChart.title ===
				"Marketing lead to sales opportunity"
			) {
				this.dataRecords = this.records.lead.opportunities.map((o) => {
					return {
						...o,
						closeDate: o.closeDate && formatDate(o.closeDate) || "",
						createdAt: o.createdAt && formatDate(o.createdAt) || "",
						leadCreatedAt: o.leadCreatedAt && formatDate(o.leadCreatedAt) || "",
					}
				});
				if (this.corporateInstance) {
					this.dataRecords = this.dataRecords.filter(r => this.selectedProjects.includes(r.instanceName))
				}

				return [
					{
						title: "Project",
						dataIndex: "instanceName",
						key: "instanceName",
						sorter: (a, b) =>
							a.instanceName < b.instanceName ? -1 : a.instanceName > b.instanceName ? 1 : 0,
					},
					{
						title: "Name",
						dataIndex: "name",
						key: "name",
						sorter: (a, b) =>
							a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
					},
					{
						title: "Value",
						dataIndex: "value",
						key: "value",
						scopedSlots: { customRender: "value" },
						sorter: (a, b) =>
							a.value < b.value ? -1 : a.value > b.value ? 1 : 0,
					},
					{
						title: "Primary Contact",
						dataIndex: "contact",
						key: "contact",
						sorter: (a, b) =>
							a.contact < b.contact
								? -1
								: a.contact > b.contact
									? 1
									: 0,
					},
					{
						title: "Stage",
						dataIndex: "stage",
						key: "stage",
						sorter: (a, b) =>
							a.stage < b.stage ? -1 : a.stage > b.stage ? 1 : 0,
					},
					{
						title: "Source",
						dataIndex: "source",
						key: "source",
						sorter: (a, b) =>
							a.source < b.source
								? -1
								: a.source > b.source
									? 1
									: 0,
					},
					{
						title: "Sales Owner",
						dataIndex: "owners",
						key: "owners",
						sorter: (a, b) =>
							a.owners < b.owners
								? -1
								: a.owners > b.owners
									? 1
									: 0,
					},
					{
						title: "Estimated Close date",
						dataIndex: "closeDate",
						key: "closeDate",
						scopedSlots: { customRender: "closeDate" },
						sorter: (a, b) =>
							moment(a.closeDate).format("x") -
							moment(b.closeDate).format("x"),
					},
				];
			}

			if (this.selectedChart.title === "Lead Velocity Rate") {
				this.dataRecords = this.records.lead.opportunities.map((o) => {
					return {
						...o,
						leadCreatedAt: o.leadCreatedAt && formatDate(o.leadCreatedAt) || "",
						createdAt: o.createdAt && formatDate(o.createdAt) || "",
					}
				});
				if (this.corporateInstance) {
					this.dataRecords = this.dataRecords.filter(r => this.selectedProjects.includes(r.instanceName))
				}

				return [
					{
						title: "Project",
						dataIndex: "instanceName",
						key: "instanceName",
						sorter: (a, b) =>
							a.instanceName < b.instanceName ? -1 : a.instanceName > b.instanceName ? 1 : 0,
					},
					{
						title: "Name",
						dataIndex: "leadName",
						key: "leadName",
						sorter: (a, b) =>
							a.leadName < b.leadName
								? -1
								: a.leadName > b.leadName
									? 1
									: 0,
					},
					{
						title: "Email",
						dataIndex: "email",
						key: "email",
						sorter: (a, b) =>
							a.email < b.email ? -1 : a.email > b.email ? 1 : 0,
					},
					{
						title: "Lead Registration Date",
						dataIndex: "leadCreatedAt",
						key: "leadCreatedAt",
						scopedSlots: { customRender: "leadCreatedAt" },
						sorter: (a, b) =>
							moment(a.leadCreatedAt).format("x") -
							moment(b.leadCreatedAt).format("x"),
					},
					{
						title: "Lead Source",
						dataIndex: "leadSource",
						key: "leadSource",
						sorter: (a, b) =>
							a.leadSource < b.leadSource
								? -1
								: a.leadSource > b.leadSource
									? 1
									: 0,
					},
					{
						title: "Lead Source URL",
						dataIndex: "leadSourceUrl",
						key: "leadSourceUrl",
						scopedSlots: { customRender: "leadSourceUrl" },
						sorter: (a, b) =>
							a.leadSourceUrl < b.leadSourceUrl
								? -1
								: a.leadSourceUrl > b.leadSourceUrl
									? 1
									: 0,
					},
					{
						title: "Opportunity Name",
						dataIndex: "name",
						key: "name",
						sorter: (a, b) =>
							a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
					},
					{
						title: "Opportunity Created Date",
						dataIndex: "createdAt",
						key: "createdAt",
						scopedSlots: { customRender: "createdAt" },
						sorter: (a, b) =>
							moment(a.createdAt).format("x") -
							moment(b.createdAt).format("x"),
					},
					{
						title: "Opportunity Value",
						dataIndex: "value",
						key: "value",
						scopedSlots: { customRender: "value" },
						sorter: (a, b) =>
							a.value < b.value ? -1 : a.value > b.value ? 1 : 0,
					},
					{
						title: "Opportunity Stage",
						dataIndex: "stage",
						key: "stage",
						sorter: (a, b) =>
							a.stage < b.stage ? -1 : a.stage > b.stage ? 1 : 0,
					},
				];
			}

			this.dataRecords = this.records.lead.all.map((o) => {
				return {
					...o,
					leadCreatedAt: o.leadCreatedAt && formatDate(o.leadCreatedAt) || "",
					lastInteraction: o.lastInteraction && formatDate(o.lastInteraction) || "",
				}
			});
			if (this.corporateInstance) {
				this.dataRecords = this.dataRecords.filter(r => this.selectedProjects.includes(r.instanceName))
			}

			return [
				{
					title: "Project",
					dataIndex: "instanceName",
					key: "instanceName",
					sorter: (a, b) =>
						a.instanceName < b.instanceName ? -1 : a.instanceName > b.instanceName ? 1 : 0,
				},
				{
					title: "Name",
					dataIndex: "name",
					key: "name",
					sorter: (a, b) =>
						a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
				},
				{
					title: "Email",
					dataIndex: "email",
					key: "email",
					sorter: (a, b) =>
						a.email < b.email ? -1 : a.email > b.email ? 1 : 0,
				},
				{
					title: "Lead Source",
					dataIndex: "leadSource",
					key: "leadSource",
					sorter: (a, b) =>
						a.leadSource < b.leadSource
							? -1
							: a.leadSource > b.leadSource
								? 1
								: 0,
				},
				{
					title: "Lead Registration Date",
					dataIndex: "leadCreatedAt",
					key: "leadCreatedAt",
					scopedSlots: { customRender: "leadCreatedAt" },
					sorter: (a, b) =>
						moment(a.leadCreatedAt).format("x") -
						moment(b.leadCreatedAt).format("x"),
				},
				{
					title: "Lead Source URL",
					dataIndex: "leadSourceUrl",
					key: "leadSourceUrl",
					scopedSlots: { customRender: "leadSourceUrl" },
					sorter: (a, b) =>
						a.leadSourceUrl < b.leadSourceUrl
							? -1
							: a.leadSourceUrl > b.leadSourceUrl
								? 1
								: 0,
				},
				{
					title: "Lead Stage",
					dataIndex: "leadStage",
					key: "leadStage",
					sorter: (a, b) =>
						a.leadStage < b.leadStage
							? -1
							: a.leadStage > b.leadStage
								? 1
								: 0,
				},
				{
					title: "Interaction Activity",
					dataIndex: "leadScore",
					key: "leadScore",
					scopedSlots: { customRender: "leadScore" },
					sorter: (a, b) => a.leadScore - b.leadScore,
				},
				{
					title: "Last Interaction",
					dataIndex: "lastInteraction",
					key: "lastInteraction",
					scopedSlots: { customRender: "lastInteraction" },
					sorter: (a, b) =>
						moment(a.lastInteraction).format("x") -
						moment(b.lastInteraction).format("x"),
				},
			];
		},

		getCSV(records) {
			if (records.length) {
				let csv = Object.keys(records[0]).join(",");
				csv = csv.replace('leadScore', 'Interaction Activity')
				csv += "\n";

				records.forEach((row) => {
					csv += Object.values(row)
						.map((r) => {
							if (typeof r === "string") {
								return r.replaceAll(", ", " & ");
							}
							return r;
						})
						.join(",");
					csv += "\n";
				});

				const anchor = document.createElement("a");
				anchor.href =
					"data:text/csv;charset=utf-8," + encodeURIComponent(csv);
				anchor.target = "_blank";
				anchor.download = `${this.filename}.csv`;
				anchor.click();
			} else {
				this.$message.info("No records found!");
			}
		},

		getFilteredSourceUrl(url) {
			const index = url.indexOf("#sect");
			if (index > 0) {
				return url.substring(0, index);
			} else {
				return url;
			}
		},
	},
};
</script>

<style>
</style>
