<template>
    <div>
        <a-drawer
            :zIndex="1000"
            :width="500"
            @close="onClose"
            :visible="visible.visible"
            :wrapStyle="{overflow: 'auto',}"
            >
            <bhLoading :show="loading" />
            <div class="h-full dF fC jSB" style="margin-bottom: 60px;">
                <div class="f1">
                    <h5>Filters</h5>
                    <div style="display:inline-block">
                        <div @click="clearFilter" class="dF aC mt-3 py-2 px-4" style="border-radius:4px; border:1px solid #ECEBED; background-color:white; cursor:pointer"><div class="mr-3">Clear Filter</div><a-icon style="color:var(--danger)" type="delete" /></div>
                    </div>
                    <div class="mt-5">
                    <a-collapse expandIconPosition="right" class="collapse-display">
                        <template #expandIcon="props">
                            <a-icon type="down" :rotate="props.isActive ? -180 : 0" />
                        </template>
						<a-collapse-panel v-if="leadSources.length && leadSources.length > 0">
                            <div class="py-2" slot="header" style="border:none">Lead Source</div>
                            <a-row :gutter="[16, 16]">
                                <a-col :span="8">
                                    <div :style="filter.source && filter.source.length && filter.source.includes('any') ? 'background-color: #CAC3D0;':''" @click="selectOption('source','any')" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">Any</div>
                                </a-col>
								<a-col :span="8" v-for="(source) in leadSources" :key="source" :value="source">
									<div :style="filter.source && filter.source.length && filter.source.includes(source) ? 'background-color: #CAC3D0;':''" @click="selectOption('source',source)" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">{{source}}</div>
								</a-col>
                            </a-row>
                        </a-collapse-panel>
                    </a-collapse>
                    </div>
                </div>
                <div style="position: absolute; left: 0px; bottom: 0px; width: 100%; border-top: 1px solid rgb(233, 233, 233); padding: 10px 16px; background: rgb(255, 255, 255); text-align: right;">
                    <div class="dF jE">
                        <div class="dF ml-3">
                            <a-button size="large" class="mr-3 cancel-button" @click="onClose">CANCEL</a-button>
                            <a-button size="large" @click="submit" type="primary">APPLY FILTER</a-button>
                        </div>
                    </div>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>

import bhLoading from 'bh-mod/components/common/Loading'
export default {
    components:{bhLoading},
	props:['visible'],
    data() {
        return {
            loading:false,
            filter:{
				source:['any']
			},
        }
    },
    watch: {
        visible:{
            handler(val) {
                if (val) {;
                    this.filter = JSON.parse(JSON.stringify(this.visible.filter));
                }
            }
        }
    },
    computed:{
		leadSources() {
			return this.$store.state.records.lead.sources || [];
		}
    },
    methods:{
        submit() {
            console.log('filter', this.filter)
			this.$emit('filter', this.filter)
            this.onClose()
        },
        selectOption(type, value) {
            if (value != 'any') {
                if(this.filter[type].length == 1 && this.filter[type][0] == 'any') {
                    this.filter[type].splice(0,1)
                }
                if (!this.filter[type].includes(value)) {
                    this.filter[type].push(value)
                } else {
                    let index = this.filter[type].findIndex(x => x == value)
                    this.filter[type].splice(index,1)
                }
            } else {
                this.filter[type] = ['any']
            }
            if (this.filter[type].length == 0) {
                this.filter[type] = ['any']
            }
        },
        clearFilter() {
            this.filter = {
				source:['any']
            }
			this.$emit('filter', this.filter)
            this.onClose()
        },
        onClose() {
            console.log('Closing...')
			this.$emit('close')
        }
    }
}
</script>

<style>
.range-display .ant-tag-blue{
    color:black !important;
}
.collapse-display.ant-collapse{
    border:none !important;
}
.collapse-display.ant-collapse > .ant-collapse-item{
    border-bottom:none !important;
}
.collapse-display .ant-collapse-header{
    padding:none !important;
}
.collapse-display.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding:0 !important;
}
.collapse-display .ant-collapse-content{
    border:none !important;
}
.cancel-button.ant-btn:hover {
    border-color:#ECE9F1 !important;
}
</style>
<style scoped>
.filter-options:hover{
    background-color: #ECE9F1;
}
.hide-display{
    height:0;
    transition:height .3s ease-in-out;
    overflow: hidden;
}
.show-display{
    height:200px;
}</style>
